/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState,useRef } from 'react';
import { uploadFile } from '../services/DrFileService';
import { BSModal } from './modal/bsmodal';
import { set } from 'date-fns';


interface fileUploaderProps {
    uploaderDir:string;
    buttonStr:string;
    setIsReload : React.Dispatch<React.SetStateAction<boolean>>;
    isReload : boolean
    isUploading? :boolean
    setIsUploading? : React.Dispatch<React.SetStateAction<boolean>>;
    fileType : "image/jpeg,image/jpg" | "application/pdf" |"application/vnd.openxmlformats-officedocument.presentationml.presentation"
    NewFileName :string 
   
}
  
export const FileUploader: React.FC<fileUploaderProps> = (props) => {
    const [openPrompt,setOpenPrompt] = useState(false);
    const [promptContent,setPromptContent] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current?.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // props.setIsUploading(!props.isUploading)
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
  
            const res = uploadFile(props.uploaderDir, selectedFile,props.NewFileName);
            res.then((val)=>{
                setOpenPrompt(true);
                if(val===200)
                {
                    setPromptContent("檔案上傳成功");

                }
                else if (val===409)
                {
                    setPromptContent("檔案已存在");
                }
                else if (val===413)
                {
                    setPromptContent("檔案大小超過 10MB 限制");
                }
                else if (val===500)
                {
                    setPromptContent("檔案上傳失敗");
                    // window.location.reload()
                }
                });          
                props.setIsReload(!props.isReload)
                // props.setIsUploading(!props.isUploading) 
            }
    };


    return  <div className='my-3'>
      
                <div className="  btn text-second bg-second my-3" onClick={handleDivClick}>
                    {props.buttonStr}
                </div>
                <input   accept={props.fileType} type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                <BSModal title="上傳檔案" isOpen={openPrompt} setOpenModal={setOpenPrompt}>{promptContent}</BSModal>
            </div>
} ; 