

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';
import { getDrGroupByStuNo, getDrGroupByStuNoClass, getDrStudentByNo, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import { Action, Export2Word, IOpinionForModal, IsubTopicForModal, OpinCode, ReacherArPictureName_after, TutorialModal, currentClass, formatDateTime, geIndexBytOpinionCode, getFileNameString, getLoginInfo, getModalConfigs, replaceNewline, setSessionStudentDisplayInfo } from './tool/helper';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import Schedule from './component/schedule';
import OpinionPost11 from './component/OpinionPost11';
import OpinionStageAfter22 from './component/OpinionStageAfter22';
import EditModal from './component/EditModal';
import EditModal2 from './component/EditModal2';
import { getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionCopyToSureOpinion, updateOpinionForClear } from '../../services/EP/OpinionService';
import { IOpinion, IsubTopic, Param11OrPost11 } from '../../interfaces/EpModal';
import { checkFileExists, copyFile, deleteFile } from '../../services/DrFileService';


export interface StudentDisplay {

  ApplyName: string
  ApplyNo: string
  ApplyTitle: string
  GroupName: string
  GroupNo: string
  SchoolName: string //"國語實小        "
  StuName: string  //"趙小一       "
  StuNo: string //  "FA001-01-1"
  TeaName: string // "李老師       "
  TeaNo: string //""
  TeaTitle: string // 
  Team: {
    TStuName: string,
    TStuNo: string
    checked: boolean
  }[]
  Teachers: {
    TeaNo: string, TeaName: string, TeaTitle: string

  }[]
}




// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpStudfeedback: React.FunctionComponent<IPageProps> = () => {
  const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState(false)
  const [currentShowModalConfig, setCurrentShowModalConfig] = useState<IOpinionForModal | IsubTopicForModal>();
  const [currentLoginInfo, setCurrentLoginInfo] = useState<any>()
  const [studentInfo, setStudentInfo] = useState<StudentDisplay>()
  const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
  const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
  const [isReload, setIsReload] = useState<boolean>(false);
  const [opinion, setOpinion] = useState<IOpinion>();
 
  useEffect(() => {
    fetchdataStudentInfo();
    FetchDataOpinion() 
    setIsReload(false)
  },
    [isReload])

  const FetchDataOpinion = async () => {
    let currentLoginInfo = getLoginInfo();
    setCurrentLoginInfo(currentLoginInfo);
    console.log('rsp..currentLoginInfo.', currentLoginInfo);
    const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];
    console.log('rsp...opinion', rsp);
    const opinion = rsp[0];
    if (opinion) {
      setOpinion(opinion);
    }
  };


  /** */
  const fetchdataStudentInfo = async () => {
    /** 取得登入資料 */
    try {

      let currentLoginInfo = getLoginInfo();
      setCurrentLoginInfo(currentLoginInfo)

      const studentInfo = await getDrGroupByStuNoClass(currentClass, currentLoginInfo.LoginID) as StudentDisplay  //as StudentInfo
      // const studentInfo =studentInfoList[0] ;
      console.log("studentInfo", studentInfo);

      if (studentInfo?.GroupNo) {
        console.log("groupInfo", studentInfo);
        setStudentInfo(studentInfo)
        setSessionStudentDisplayInfo(studentInfo)
      }

    } catch (ex) {
      alert("取得學生資料發生錯誤! ");
      console.log("取得學生資料發生錯誤! ", ex)
    }
  }
  /** 我們要重做 */
  const redoOpinion11 = async () => {
    if (window.confirm('確定要重做，清空已輸入的資料重來嗎?')) {
      try {
        if (studentInfo?.GroupNo) {
          await updateOpinionForClear(studentInfo?.GroupNo);
          await deleteFile(`/upload${currentClass}/${studentInfo?.GroupNo}`,'research_pic_after.png')
          alert('清空成功 !')
          setIsReload(true)
        }
      } catch (ex) {
        alert('清空發生錯物')
      }
    }
  }

  // 由回饋前的資料重新匯入
  const copyToSureTable = async () => {
    if (window.confirm('確定由回饋前的資料重新匯入?')) {
      try {
        if (studentInfo?.GroupNo) {
          await updateOpinionCopyToSureOpinion(studentInfo?.GroupNo);
          await copyFile(`/upload${currentClass}/${studentInfo?.GroupNo}`,'research_pic_before.png',`/upload${currentClass}/${studentInfo?.GroupNo}`,'research_pic_after.png')
          alert('匯入成功 !')
          setIsReload(true)
        }
      } catch (ex) {
        alert('清空發生錯物')
      }
    }
  }

  const openEditModal = async (opinCode: OpinCode, action: Action, param11?: Param11OrPost11, subTopic?: IsubTopic, enable?: boolean) => {
    if (enable) {
      return
    }
    let modalConfig = getModalConfigs(opinCode, action, 1);
    let params = {
      UID: undefined,
      ParamType: modalConfig?.sourceType,
      GroupNo: studentInfo?.GroupNo,
      MemberNo: studentInfo?.StuNo,
      DataA: '',
      DataB: '',
      DataC: ''
    } as paramsCreateOpinionSubTopic;

    setFormData(params);
    /**  彈出視窗設定檔*/
    if (studentInfo) {
      if (action === '新增') {
        params = {
          action: '新增',
          UID: undefined,
          ParamType: modalConfig?.sourceType,
          GroupNo: studentInfo?.GroupNo,
          MemberNo: studentInfo?.StuNo,
          DataA: '',
          DataB: '',
          DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        //需要有設定檔 跟 修改對象 ID
      } else if (action === '修改') {
        // fectchData todo
        if (modalConfig) {
          //如果沒有 傳進來的物件
          if (param11 && !subTopic) {
            // params
            params = {
              action: '修改',
              UID: param11.OpinionID,
              ParamType: modalConfig.sourceType,
              GroupNo: studentInfo?.GroupNo,
              MemberNo: studentInfo?.StuNo,
              DataA: param11.DataA,
              DataB: param11.DataB,
              DataC: '',
              termsStringList: param11.DataB.split('、')
            } as paramsCreateOpinionSubTopic;
            setFormData(params);
          } else if (!param11 && subTopic) {
            // 沒有物件
            try {
              params = {
                action: '修改',
                UID: subTopic.uid,
                ParamType: modalConfig.sourceType,
                GroupNo: studentInfo?.GroupNo,
                MemberNo: studentInfo?.StuNo,
                SubNo: subTopic.SubNo + '',
                SubName: subTopic.SubName,
                Description: subTopic.Description
              } as paramsCreateOpinionSubTopic;

              setFormData(params);
            } catch (ex) {
              alert('ex:' + JSON.stringify(ex));
            }
          }
        }
      } else if (action === '新增或修改') {
     
        if (modalConfig) {
          // 有moaal 設定檔
          if (!param11 && !subTopic) {
            // topic
            try {
              let optionCode = geIndexBytOpinionCode(opinCode);
              let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, studentInfo?.GroupNo)) as any[];
              if (rsp.length > 0) {
                // 原本有資料 設定修改
                params = {
                  action: '修改',
                  UID: rsp[0].OpinionID || rsp[0].uid,
                  ParamType: modalConfig.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: rsp[0].DataA || null,
                  DataB: rsp[0].DataB || null,
                  DataC: rsp[0].DataC || null
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
              } else {
                // 沒有資料就設定  新增
                params = {
                  action: '新增',
                  UID: undefined,
                  ParamType: modalConfig?.sourceType,
                  GroupNo: studentInfo?.GroupNo,
                  MemberNo: studentInfo?.StuNo,
                  DataA: '',
                  DataB: '',
                  DataC: ''
                } as paramsCreateOpinionSubTopic;
                setFormData(params);
              }
            } catch (ex) {
              alert('ex:' + JSON.stringify(ex));
            }
          } else if (!param11 && subTopic) {
          } else if (param11 && !subTopic) {
            //opinion 一對一
          }
        }
      } else if (action === '刪除') {
      }
    }

    // console.log( getModalConfigs(stage,modalName) )
    if (getModalConfigs(opinCode, action, 1)) {
      setCurrentShowModal(getModalConfigs(opinCode, action, 1));
    }
    setIsAddRsearchModalOpen(true)
  };


  return (
    <>

      <TopNavPage1 />
      <div className="section pt-3">
        <div className="container px-3 px-md-4">
          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <h5 className="title mb-3 me-3">專題探究競賽</h5>
            <StudentFunctionBar />
          </div>
{/* {JSON.stringify(isReload) }sss */}
          <div className="accordion" id="accordionRental">
            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="accordion-button flex-wrap text-dark p-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#level0" aria-expanded="true" aria-controls="level0">
                  <h4 className="my-0 me-3">專家回饋後，是否修改「階段1和2」的題目與內容?</h4>
                  <div className="text-second font-weight-bold">各隊可以參考專家者的回饋，決定是否修正或重來。</div>
                  <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                </button>
              </div>
              <div id="title-info"  hidden>
                     {studentInfo?.SchoolName.trim()}- {studentInfo?.GroupName.trim()}-({studentInfo?.GroupNo})

            </div>
              <div className="collapse show" id="level0" data-bs-parent="#accordionRental">
                <div className="bd-callout d-flex flex-wrap justify-content-between align-items-end px-4 py-3 mt-0 mb-3">
                  <ul className="mb-0">
                    <li>專家學者回饋意見僅供參考，各隊可評估是否修改。</li>
                    <li>如果想<span className="fw-bold text-green">重做</span>，請按《我們要重做》按鈕，再一一鍵入新資料。(<span
                      className="fw-bold text-green">注意!資料會清空</span> )</li>
                    <li>如果<span className="fw-bold text-green">部分修改</span>，請在下方頁面想改的地方編修。</li>
                    <li>無論是否重做或修改，<span className="fw-bold text-green">請說明原因</span>，按《範例顯示》按鈕參考寫法。</li>
                    <li>完成此頁面，再前往<span className="fw-bold text-green">階段3和4接續探究。</span></li>
                  </ul>

                </div>
                <div className="card p-4 pt-3">
                  <div className="d-flex flex-wrap align-items-center my-3">
                    <div className="fw-bolder text-second">我們不修改、修改或重做的原因：</div>
                    <div className="btn text-second bg-second mb-0 pointer" data-bs-toggle="collapse" data-bs-target="#sample"
                      aria-expanded="false" aria-controls="sample">範例顯示</div>
                    <div className="btn text-second bg-second mb-0 pointer ms-4"
                      onClick={() => copyToSureTable()}>
                      由回饋前的資料重新匯入
                    </div>
                    <div className="btn text-second bg-second mb-0 pointer ms-4"
                      onClick={() => redoOpinion11()}>
                      我們要清空重作
                    </div>
                  </div>
                  <div id="sample" className="accordion-collapse bg-white border-bottom collapse" aria-labelledby="heading1">
                    <div className="accordion-body d-flex flex-wrap bg-form-coll mb-3">
                      <div className="col-12 col-sm-6 col-lg-4 my-3">
                        <div>【例一】</div>
                        <div className="ps-3">我們不修改題目和內容。</div>
                        <div className="ps-3">因為……</div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4 my-3">
                        <div>【例二】</div>
                        <div className="ps-3">我們修改題目</div>
                        <div className="ps-3">我們原來的題目為 - XXXXX</div>
                        <div className="ps-3">修改後的題目為 – YYYYY</div>
                        <div className="ps-3">因為……</div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4 my-3">
                        <div>【例三】</div>
                        <div className="ps-3">我們修改部分內容</div>
                        <div className="ps-3">2-2探究架構做了調整</div>
                        <div className="ps-3">因為……</div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4 my-3">
                        <div>【例四】</div>
                        <div className="ps-3">我們整個重做</div>
                        <div className="ps-3">因為……</div>
                      </div>
                    </div>
                  </div>
                  <div className="concard p-3">
                    <div className="d-flex align-items-center pb-2">
                      <div className="fw-bolder text-second">我們不修改、修改或重做的原因：</div>
                      <span className="material-icons pointer ms-2" data-bs-toggle="modal" data-bs-target="#editModal0"
                        title="編輯"
                        onClick={() => openEditModal('3_0回饋意見後修改', '新增或修改')}
                      >edit</span>
                    </div>

                    {/* 我們修改題目<br/>
                  我們原來的題目為 - XXXXX<br/>
                  修改後的題目為 – YYYYY<br/>
                  因為…… */}

                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post30_DataA || '') }}></div>

                    <div className="pt-3 pb-2">
                      <span className="fw-bolder text-second">日期：</span>
                      {/* <span>2023/02/20 下午 03:39:00</span> */}
                      <span>{formatDateTime(opinion?.Post30_UpdateDate)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#level1" aria-expanded="false" aria-controls="level1">
                  <h4 className="my-0 me-3">階段1、決定探究題目</h4>

                  <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                </button>
                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: "280px" }}>
                  <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach1">
                    怎樣選擇題目
                  </div>
                  <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-stage1', '階段1、', getFileNameString(undefined ,studentInfo))}>
                    匯出檔案
                  </div>
                </div>
              </div>

              <div className="collapse" id="level1" data-bs-parent="#accordionRental">

                <OpinionPost11   
                opinion={opinion}
                groupNo={studentInfo?.GroupNo} 
                role ={'DrStudent'} studentInfo={studentInfo} currentLoginInfo={currentLoginInfo}
                onFinish={() => setIsReload(true)}
                 setStudentInfo={setStudentInfo} />
              </div>
            </div>

            <div className="accordion-item">
              <div className="card bd-callout bd-callout-danger">
                <button className="accordion-button text-dark collapsed p-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#level2" aria-expanded="false" aria-controls="level2">
                  <h4 className="my-0 me-2">階段2、規劃探究架構</h4>

                  <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                  <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                </button>
                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: "280px" }}>
                  {/* <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach2">
                    怎樣規劃探究架構
                  </div>
                  <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('stage2-after', '階段2、規劃探究架構')}>
                    匯出檔案
                  </div> */}
                </div>
              </div>

              <EditModal
                groupNo={studentInfo?.GroupNo}
                isAddRsearchModalOpen={isAddRsearchModalOpen}
                setIsAddRsearchModalOpen={setIsAddRsearchModalOpen}
                currentShowModal={currentShowModal}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                formData={formData}
                setFormData={setFormData}
                onFinish={() => {setIsReload(true)}} />

              <OpinionStageAfter22 
                selectGroupInfo={ undefined}
                onFinish={() => setIsReload(true)}
                groupNo={studentInfo?.GroupNo} 
                role ={'DrStudent'} 
                opinion ={opinion} 
                studentInfo={studentInfo} 
                setStudentInfo={setStudentInfo} 
                isFeedback={1} />
            </div>
          </div>

          {/*  */}
          <TutorialModal 
        id="teach1" 
        title="階段1 教學" 
        // src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9" 
        src="//www.slideshare.net/slideshow/embed_code/key/6tppHRHcTHtMut" 
        href="//www.slideshare.net/LoveBook2/1202301pptx" 
      />
      <TutorialModal 
        id="teach2" 
        title="階段2 教學" 
        src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd" 
        href="//www.slideshare.net/LoveBook2/2202301pptx" 
      />
          <div className="pt-3 mt-4"></div>

        </div>
      </div>
      <FooterPage />
    </>

  )


}

export default EpStudfeedback;