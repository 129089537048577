import React, { useEffect, useState } from 'react';



import { IsubTopicForModal, alertEx, currentClass, currentClassForBtn, geIndexBytOpinionCode, getClassNameForShow, getLoginInfo, getModalConfigs, replaceNewline, sourceType, validate } from '../tool/helper';
import { IOpinion, IsubTopic, Param11OrPost11, crtlInfo, subTopicParam } from '../../../interfaces/EpModal';
import { StudentDisplay } from '../epStudentHome';
import {  createOpinion, deleteOpinionByUID, deleteSubTopicByGroupNoSubNo, getOpinionByGroupOpIdx, paramsCreateOpinionSubTopic, updateOpinionByGroupOpIdxUID, updateSubTopicByUid } from '../../../services/EP/OpinionService';
import {
    Action as action,
    IOpinionForModal,
    formatDateTime,
    OpinCode,

} from '../tool/helper';
import { getOpinionByStuNo } from '../../../services/EP/EpApplyStudentService';
import { Modal } from 'react-bootstrap';
import EditModal from './EditModal';
import { getOpinionByGroupNo } from '../../../services/EP/EpApplyExploreService';
interface IProp{
    studentInfo :StudentDisplay|undefined
    groupNo :string |undefined
    /** 登入者狀態 */
    currentLoginInfo :any 
    setStudentInfo : (studentDisplay :StudentDisplay) => void; 
    role :string
    opinion :IOpinion  |undefined
    // setisReload : React.Dispatch<React.SetStateAction<boolean>>
    onFinish : ()=>void 
}





// 1-1 提出探究題目 (回覆後)
const OpinionPost11: React.FunctionComponent<IProp> = (props) => {

    const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();
    // const [opinion, setOpinion] = useState<IOpinion>();
    const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
    // const [isReload, setIsReload] = useState<boolean>(true);
    const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState<any>();
    /**取的opinion 資料 */



    const openEditModal = async (opinCode: OpinCode, action: action, param11?: Param11OrPost11, subTopic?: IsubTopic,enable?:boolean ) => {
  
        if(enable ){
            return 
        }
        let modalConfig = getModalConfigs(opinCode, action ,1);
        let params = {
            UID: undefined,
            ParamType: modalConfig?.sourceType,
            GroupNo: props.studentInfo?.GroupNo,
            MemberNo: props.studentInfo?.StuNo,
            DataA: '',
            DataB: '',
            DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        /**  彈出視窗設定檔*/
        if (props.groupNo) {
            if (action === '新增') {
                params = {
                    action: '新增',
                    UID: undefined,
                    ParamType: modalConfig?.sourceType,
                    GroupNo: props.groupNo ,
                    MemberNo: props.studentInfo?.StuNo ||'',
                    DataA: '',
                    DataB: '',
                    DataC: ''
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
                //需要有設定檔 跟 修改對象 ID
            } else if (action === '修改') {
                // fectchData todo
                if (modalConfig) {
                    //如果沒有 傳進來的物件
                    if (param11 && !subTopic) {
                        // params
                        debugger 
                        // alert(JSON.stringify(param11))
                        params = {
                            action: '修改',
                            UID: param11.OpinionID,
                            ParamType: modalConfig.sourceType,
                            GroupNo: props.groupNo ,
                            MemberNo: props.studentInfo?.StuNo,
                            DataA: param11.DataA,
                            DataB: param11.DataB,
                            DataC: '',
                            termsStringList : param11.DataB.split('、')
                        } as paramsCreateOpinionSubTopic;
                        setFormData(params);
                    } else if (!param11 && subTopic) {
                        // 沒有物件
                        try {
                            params = {
                                action: '修改',
                                UID: subTopic.uid,
                                ParamType: modalConfig.sourceType,
                                GroupNo: props.groupNo ,
                                MemberNo: props.studentInfo?.StuNo,
                                SubNo: subTopic.SubNo + '',
                                SubName: subTopic.SubName,
                                Description: subTopic.Description
                            } as paramsCreateOpinionSubTopic;
                   
                            setFormData(params);
                        } catch (ex) {
                            alert('ex:' + JSON.stringify(ex));
                        }
                    }
                }
            } else if (action === '新增或修改') {
                if (modalConfig) {
                    // 有moaal 設定檔
                    if (!param11 && !subTopic) {
                        // topic
                        try {
                            let optionCode = geIndexBytOpinionCode(opinCode);
                            let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, props.groupNo )) as any[];
                            if (rsp.length > 0) {
                                // 原本有資料 設定修改
                                params = {
                                    action: '修改',
                                    UID: rsp[0].OpinionID || rsp[0].uid,
                                    ParamType: modalConfig.sourceType,
                                    GroupNo: props.studentInfo?.GroupNo,
                                    MemberNo: props.studentInfo?.StuNo,
                                    DataA: rsp[0].DataA || null,
                                    DataB: rsp[0].DataB || null,
                                    DataC: rsp[0].DataC || null
                                } as paramsCreateOpinionSubTopic;

                                setFormData(params);
                            } else {
                                // 沒有資料就設定  新增
                                params = {
                                    action: '新增',
                                    UID: undefined,
                                    ParamType: modalConfig?.sourceType,
                                    GroupNo: props.studentInfo?.GroupNo,
                                    MemberNo: props.studentInfo?.StuNo,
                                    DataA: '',
                                    DataB: '',
                                    DataC: ''
                                } as paramsCreateOpinionSubTopic;
                                setFormData(params);
                            }
                        } catch (ex) {
                            alertEx('ex:' + JSON.stringify(ex));
                        }
                    } else if (!param11 && subTopic) {
                    } else if (param11 && !subTopic) {
                        //opinion 一對一
                    }
                }
            } else if (action === '刪除') {
            }
        }

        // console.log( getModalConfigs(stage,modalName) )
        if (getModalConfigs(opinCode, action,1)) {
            setCurrentShowModal(getModalConfigs(opinCode, action,1));
        }
        setIsAddRsearchModalOpen(true);
    };

    
    const deleteItem = async (deleteType: sourceType, subTopic?: IsubTopic, param11?: Param11OrPost11,enable?:boolean) => {
        if(enable){
            return 
        }
     
        if (window.confirm('確定刪除?')) {
            try {
                if (deleteType === 'subTopic' && subTopic) {
                    if (subTopic.GroupNo && subTopic.uid) {
                        await deleteSubTopicByGroupNoSubNo(1, subTopic.GroupNo, subTopic.SubNo);
                    }
                } else if (deleteType === 'opinion' && param11) {
                    await deleteOpinionByUID(1, param11.OpinionID);
                }
                alertEx('刪除成功!');
                props.onFinish();
            } catch (ex) {
                console.log();
            }
        }
    };
    /** */

    return (
      <>
                   <div className="card p-4 pt-3 " id="print-stage1">
                                    <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                    <div className="d-flex flex-wrap align-items-end">
                                        <ul>
                                            <li>每個學生最少提出一個題目，最好不超過3個。不能編輯其他成員的資料。</li>
                                            <li>
                                                此階段希望每個學生都能先提出<b>「自己」「想探究」</b>的題目，並說明理由。
                                            </li>
                                        </ul>
                                        <div
                                            className={"btn text-second bg-second mb-0 pointer ms-auto "+  getClassNameForShow(['DrStudent'],props.role,"btn text-second bg-second mb-0 pointer ms-auto")} 
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-1"
                                            onClick={() => openEditModal('1_1提出探究題目', '新增')}
                                        >
                                            <span className="material-icons-outlined align-middle">add</span>
                                            <span>提出探究題目</span>
                                        </div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6;' }}>
                                                    <th className="p-2 text-nowrap">成員</th>
                                                    <th className="p-2 text-nowrap">我想探究的題目</th>
                                                    <th className="p-2 text-nowrap">想探究這個題目的理由    </th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className={"p-2 text-nowrap print-remove"+ getClassNameForShow(['DrStudent'],props.role,"p-2 text-nowrap print-remove")} >功能</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.opinion?.Post11.map((item) => (
                                                    <tr>
                                                        <td className="text-nowrap">{item.StuName}</td>
                                                        <td>{item.DataA}</td>
                                                        <td>{item.DataB}</td>
                                                        <td> {formatDateTime(item.UpdateDate)}</td>
                                                        <td  className="text-nowrap print-remove">
                                                         
                                                            <span
                                                                // className={item.StuNo === props.studentInfo?.StuNo ? 'material-icons text-dblue cursor-pointer' : 'material-icons text-gray'}
                                                                className={  getClassNameForShow(['DrStudent'],props.role,'material-icons ',item.StuNo !== props.studentInfo?.StuNo )}
                                                                title="刪除"
                                                                onClick={() => deleteItem('opinion', undefined, item ,item.StuNo !== props.studentInfo?.StuNo)}
                                                            >
                                                                delete_outline
                                                            </span>
                                                            <span
                                                                // className={item.StuNo === props.studentInfo?.StuNo ? 'material-icons text-dblue cursor-pointer' : 'material-icons text-gray'}
                                                                 className={  getClassNameForShow(['DrStudent'],props.role,'material-icons ',item.StuNo !== props.studentInfo?.StuNo )}
                                                                title="編輯"
                                                                onClick={() => openEditModal('1_1提出探究題目', '修改', item, undefined,item.StuNo !== props.studentInfo?.StuNo)}
                                                            >
                                                                edit
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，決定一個題目。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        {/* { getClassNameForShow(['DrStudent'],props.role,'material-icons float-end pointer ')} */}
                                        <span 
                                          className={  getClassNameForShow(['DrStudent'],props.role,"material-icons float-end pointer")}
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-2"
                                            title="編輯"
                                            onClick={() => openEditModal('1_2決定探究題目', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pb-2">
                                            我們決定探究的題目：
                                            <span className="text-primary">{props.opinion?.Post12_DataA}</span>
                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post12_DataB || '') }}>
                                          
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>
                                         
                                                {formatDateTime(props.opinion?.Post12_UpdateDate)}
                                            </span>
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">最後編輯：</span>
                                            <span>
                                                {/* 2023/02/20 下午 03:39:00  */}
                                                {props.opinion?.Post12_UpdateDate?replaceNewline(props.opinion?.Pre12_StuName):''}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，預測可能的結果。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                            className={ getClassNameForShow(['DrStudent'],props.role,'material-icons float-end pointer')}
                                          
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-3"
                                            title="編輯"
                                            onClick={() => openEditModal('1_3探究題目預測結果', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們預測可能的結果是：</div>
                                        <div>
                                     
                                            <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post13_DataB || '') }}></div>
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(props.opinion?.Post13_UpdateDate)}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <ul>
                                        <li>請全體隊員共同回顧在「階段1」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                          className={  getClassNameForShow(['DrStudent'],props.role,'material-icons float-end pointer')}
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-4"
                                            title="編輯"
                                            onClick={() => openEditModal('1_4困難、解決與新學習', '新增或修改')}
                                        >
                                            edit
                                        </span>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段1」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post14_DataA || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post14_DataB || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post14_DataC || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(props.opinion?.Post14_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    {/* pin  */}
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    {/* <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post19_DataA || '') }}>
                                     
                                    </div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(props.opinion?.Post19_UpdateDate)}</span>
                                    </div> */}
                                              <div className="concard p-3">
                                    <span
                                            // className="material-icons float-end pointer"
                                            className={ getClassNameForShow(['DrTeacher'], props.role ,"material-icons float-end pointer"  )}
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal2-3"
                                            title="編輯"
                                            onClick={() => openEditModal('1_9指導老師留言', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(props.opinion?.Post19_DataA || '') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(props.opinion?.Post19_UpdateDate)}</span>
                                        </div>  
                                    </div>
                                </div>

      <div>




            <EditModal groupNo={props.groupNo}  isAddRsearchModalOpen={isAddRsearchModalOpen}  
                        setIsAddRsearchModalOpen ={setIsAddRsearchModalOpen}
                         currentShowModal ={currentShowModal}
                         studentInfo = {props.studentInfo}
                         setStudentInfo = {props.setStudentInfo}
                         formData={ formData}
                         setFormData={setFormData}
                         onFinish={()=>props.onFinish()}
                        />
                 
      </div>
      </>
    );
};
export default OpinionPost11;


