import React, { useState, useRef } from 'react';
import { uploadFile } from '../services/DrFileService';
import { BSModal } from './modal/bsmodal';

interface fileUploaderProps {
    uploaderDir: string;
    buttonStr: string;
    setIsReload: React.Dispatch<React.SetStateAction<boolean>>;
    isReload: boolean;
    isUploading?: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    fileType: "image/jpeg,image/jpg" | "application/pdf" |
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" |
        "application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint" |
        ".pptx";
    NewFileName: string;
}

const FileUploaderNew: React.FC<fileUploaderProps> = ({
    uploaderDir,
    buttonStr,
    setIsReload,
    isUploading,
    setIsUploading,
    NewFileName,
    fileType
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);

    const resetFileInput = () => {
        // Reset the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const triggerFileInput = () => {
        resetFileInput(); // Reset before opening file dialog
        fileInputRef.current?.click();
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        console.log("files", files);

        if (files && files.length > 0) {
            setIsUploading(true);

            // Check file type
            if (fileType == '.pptx'&& files[0].type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                setModalContent("檔案格式只接受pptx");
                setIsUploading(false);
                setModalOpen(true);
                resetFileInput();
                return;
            }

            try {
                const response = await uploadFile(uploaderDir, files[0], NewFileName);
                
                switch (response) {
                    case 200:
                        setModalContent("檔案上傳成功");
                        setIsReload(prev => !prev);
                        break;
                    case 409:
                        setModalContent("檔案已存在");
                        break;
                    case 413:
                        setModalContent("檔案大小超過 10MB 限制");
                        break;
                    case 500:
                        setModalContent("檔案上傳失敗");
                        break;
                    default:
                        setModalContent("上傳過程中發生錯誤");
                }
            } catch (error) {
                console.error("Error uploading file:", error);
                setModalContent("上傳過程中發生錯誤");
            } finally {
                setIsUploading(false);
                setModalOpen(true);
                resetFileInput(); // Reset after upload attempt
            }
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent("");
    };

    return (
        <div>
            <div 
                className="btn text-second bg-second my-3" 
                onClick={triggerFileInput}
                style={{ cursor: isUploading ? 'not-allowed' : 'pointer' }}
            >
                {buttonStr}
            </div>
            <input
                accept={fileType}
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                disabled={isUploading}
                // Key point: onClick handler to reset value
                onClick={(event) => {
                    const target = event.target as HTMLInputElement;
                    target.value = '';
                }}
            />
            {isUploading && <p>檔案上傳中...</p>}
            <BSModal 
                title="上傳檔案" 
                isOpen={modalOpen} 
                setOpenModal={handleModalClose}
            >
                {modalContent}
            </BSModal>
        </div>
    );
};

export default FileUploaderNew;
