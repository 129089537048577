import React, {useEffect, useRef, useState} from 'react'
import IPageProps from '../interfaces/page'
import { handleLink } from '../modules/utils';
import { useSysInfoContext } from '../components/sysContext';
import {Link, useHistory} from 'react-router-dom';
import { set } from 'date-fns';
import FileUploaderNew from '../components/fileUploaderNew';
import { copyFile, deleteFile, moveFile, renameFile } from '../services/DrFileService';



const MainFuncPage: React.FunctionComponent<IPageProps> = props => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {sharedState,setSharedState} = useSysInfoContext();
  //  const [reload, setReload] = useState(false);
    let history = useHistory();

    const handleDelete = () => {
        console.log("handleDelete");
        const resp=deleteFile("uploads","test.pptx");
        console.log("delete",resp);
    }

    const handleRename = () => {
        console.log("handleDelete");
        const resp=renameFile("uploads","test.pptx","test1.pptx");
        console.log("rename",resp);
    }

    const handleCopy = () => {
        console.log("handleCopy");
        const resp=copyFile("uploads","test.pptx","uplad1","test1.pptx");
        console.log("rename",resp);
    }

    const handleMove = () => {
        console.log("handleMove");
        const resp=moveFile("uploads","test.pptx","uplad1","test2.pptx");
        console.log("rename",resp);
    }

    const navigate = () => {
        history.push('/pptest');
    };
    const handleContext = ()=>{
        setSharedState("ContextTest");
        console.log("sharedState",sharedState);    
    }
   // console.log("reload",reload);
   // useEffect(() => {
   //     console.log("reload",reload);
   //     if(reload){
   //         setReload(false);
   //     }
   // }, [reload]);
    return (
        <>
            <header className="section-gray">
                <div className="container d-flex flex-column flex-sm-row px-0 px-md-4">
                    <div className="pp pointer" >
                        <img src="../assets/img/dp.svg" onClick={()=> handleLink('/pptest')}/> 
                    </div>
                    <div className="dp pointer">
                        <img src="../assets/img/dp.svg" onClick={()=> handleLink('/dptest')}/> 
                    </div>
                    <div className="ep pointer" >
                         <img src="../assets/img/ep.svg" onClick={()=> handleLink('/eptest')}/> 
                         {/* <img src="../assets/img/ep.svg"  alt="施工" title="施工中" /> */}
                         {/*<img src="../assets/img/ep.svg"  onClick={()=> handleLink('/eplogin')} /> */}
                    </div>
                </div>
            </header>
        {/*    <FileUploaderNew 
                    uploaderDir="uploads" 
                    buttonStr="上傳檔案"
                    setIsReload={setReload}
                    isReload={reload}
                    isUploading={false} 
                    setIsUploading={()=>{}}
                    NewFileName="test.pptx" 
                    fileType=".pptx" 
            />
            <button onClick={()=>handleDelete()}>TestDelete</button>   
            <button onClick={()=>handleRename()}>Rename</button>
            <button onClick={()=>handleCopy()}>Copy</button>
            <button onClick={()=>handleMove()}>Move</button>       */}
        </>
    );
}

export default MainFuncPage;